.social-login-container {
    display: flex;
    justify-content: space-between;
    gap: 16px;
    box-sizing: border-box;

    max-width: 100%;
  }
  
  .social-login-button {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-weight: 600;
    gap: 10px;
    color: #fff;
    background-color: transparent;
    flex: 1 1 0;
    min-width: 0;
    height: 80px;
    border: 1px solid #fff;
  }
  
  @media (max-width: 768px) {
    .social-login-button {
      font-size: 12px;
      padding: 10px 2px;
    }
  }
  
  @media (min-width: 769px) {
    .social-login-button {
      font-size: 14px;
      padding: 10px;
    }
  }
  
  .social-login-button:disabled {
    background-color: rgba(255, 255, 255, 0.1);
    border: none;
    cursor: not-allowed;
    opacity: 0.7;
  }
  
  .social-login-button:not(:disabled):hover {
    transform: scale(1.05);
    opacity: 0.9;
    background-color: #333;
    border: 1px solid #fff;
  }
  
  .social-login-button img,
  .social-login-button svg {
    width: 24px;
    height: 24px;
    object-fit: contain;
  }