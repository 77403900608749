@font-face {
  font-family: 'Graphik-Light';
  src: url('./Assets/fonts/Graphik/Graphik-Light.woff');
}

@font-face {
  font-family: 'Graphik-Bold';
  src: url('./Assets/fonts/Graphik/Graphik-Bold.woff');
}

@font-face {
  font-family: 'Graphik-Super';
  src: url('./Assets/fonts/Graphik/Graphik-Super.woff');
}

@font-face {
  font-family: 'Graphik-Semibold';
  src: url('./Assets/fonts/Graphik/Graphik-Semibold.woff');
}

@font-face {
  font-family: 'Graphik-Regular';
  src: url('./Assets/fonts/Graphik/Graphik-Regular.woff');
}

@font-face {
  font-family: 'Graphik-Medium';
  src: url('./Assets/fonts/Graphik/Graphik-Medium.woff');
}

@font-face {
  font-family: 'Graphik-Black';
  src: url('./Assets/fonts/Graphik/Graphik-Black.woff');
}

@font-face {
  font-family: 'Graphik-Thin';
  src: url('./Assets/fonts/Graphik/Graphik-Thin.woff');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Graphik-Extralight';
  src: url('./Assets/fonts/Graphik/Graphik-Extralight.woff');
  font-weight: 200;
  font-style: normal;
}
