


.login-form-input {
  width: 100%;
  padding: 12px;
  background-color: #333;
  border: 1px solid #444;
  border-radius: 8px;
  color: #fff;
  font-size: 14px;
  margin-bottom: 16px;
  box-sizing: border-box;
}


.login-form-input:disabled {
  background-color: #444;
  cursor: not-allowed;
}

.login-button {
  width: 100%;
  padding: 14px;
  background-color: #9333ea;
  color: #fff;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  margin-top: 16px;
  box-sizing: border-box;
}

.login-button:disabled {
  background-color: #d4c4e3;
  cursor: not-allowed;
}
